$(document).foundation();
$(document).ready(function () {
    initSliders();
    initGalleries();
    initCapacityTables();
    initBookingWidget();
    initMobile();
    initOfferFilters();
    initVerticalCenter();
    initAccordions();
    initGridPanels();
    initVideoHeader();
});

jQuery.loadScript = function (url, callback) {
    jQuery.ajax({
        url: url,
        dataType: 'script',
        success: callback,
        async: true
    });
}


$('.business-list ul .mobile a').on('click', function (event) {

	$(this).next('.hidden').toggle();

	event.preventDefault()

});


$('.header__button--book').on('click', function () {

	$.loadScript('https://bookings.liveres.co.uk/scripts/fez.js', function(){
	    //alert ('hi');
	});

});


function initGridPanels() {
    $('.news-and-views__panel')
        .on('on.zf.toggler', function () {
            var panel = $(this);

            // close any other details panels
            var panels = $(this).closest('.news-and-views').find('.news-and-views__panel--active').not(panel);

            if (panels.length > 0) {
                panels.foundation('toggle');
            }

             if($(window).width() < 767)
		    {
		       // get height of detail content
            var cell = panel.parent();
            var detailsHeight = cell.find('.news-and-views__details').outerHeight();

            cell.find('> div:last-child').css('margin-top', detailsHeight + 130);
		    } else {
		        // get height of detail content
            var cell = panel.parent();
            var detailsHeight = cell.find('.news-and-views__details').outerHeight();

            cell.find('> div:last-child').css('margin-top', detailsHeight + 60);
		    }
        })
        .on('off.zf.toggler', function () {
            var cell = $(this).parent();

            // remove margin
            cell.find('> div:last-child').css('margin-top', 0);
        });

    $('.news-and-views__details-close').on('click', function () {
        // close details panel
        var cell = $(this).closest('.cell');
        cell.find('.news-and-views__panel').foundation('toggle');
    });

   /*  if ($('.news-and-views__panel').length > 0) {
	    stretchLastGridPanel();
	    $(window).on('resize', stretchLastGridPanel);
	} */
};

function stretchLastGridPanel() {
	// Reset first...
    $('.news-and-views > .grid-x > .cell').last().attr('class', 'cell medium-6 large-4')

    // ...then decide on the right class to set the width of the last item
    if ( (Foundation.MediaQuery.current.indexOf('large') > -1) ) {
	    if ($('.news-and-views__panel').length % 3 === 2) {
		    $('.news-and-views > .grid-x > .cell').last().attr('class', 'cell medium-12 large-8')

	    } else if ($('.news-and-views__panel').length % 3 === 1) {
		    $('.news-and-views > .grid-x > .cell').last().attr('class', 'cell medium-6 large-12')
	    }
	} else if (Foundation.MediaQuery.current === 'medium') {
		if ($('.news-and-views__panel').length % 2 === 1) {
		    $('.news-and-views > .grid-x > .cell').last().attr('class', 'cell medium-12 large-8')
	    }
	}

	$('.news-and-views__panel')
        .on('on.zf.toggler', function () {
            var panel = $(this);

            // close any other details panels
            var panels = $(this).closest('.news-and-views').find('.news-and-views__panel--active').not(panel);

            if (panels.length > 0) {
                panels.foundation('toggle');
            }

             if($(window).width() < 767)
		    {
		       // get height of detail content
            var cell = panel.parent();
            var detailsHeight = cell.find('.news-and-views__details').outerHeight();

            cell.find('> div:last-child').css('margin-top', detailsHeight + 130);
		    } else {
		        // get height of detail content
            var cell = panel.parent();
            var detailsHeight = cell.find('.news-and-views__details').outerHeight();

            cell.find('> div:last-child').css('margin-top', detailsHeight + 60);
		    }

        })
        .on('off.zf.toggler', function () {
            var cell = $(this).parent();

            // remove margin
            cell.find('> div:last-child').css('margin-top', 0);
        });


}

function initAccordions() {

$('.opening-hours-accordion').accordiom({

});

}

function initVerticalCenter() {
    $('.vertical-center').each(function () {
        $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
    });
}

function initSliders() {
    $('[data-slick]').slick({
        prevArrow: '<button type="button" class="slick-prev"><i class="icon-chevron-thin-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon-chevron-thin-right"></i></button>'
    });

    $('.kitchen-carousel').slick({
        arrows: true,
        dots: true,
        autoplay: false,
        infinite: true,
        centerMode: true,
        centerPadding: '20%',
        slidesToShow: 1,
        slidesToScroll: 1,
    });

    $('.kitchen-carousel-mobile').slick({
        arrows: true,
        dots: true,
        autoplay: false,
        infinite: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    });
}

function initGalleries() {
    $('[data-gallery]').on('click', function () {
        $('.gallery-popup__inner')
        .empty()
        .load($(this).data('gallery'), function () {
            $('.gallery-popup .slider').slick({
                prevArrow: '<button type="button" class="slick-prev"><i class="icon-chevron-thin-left"></i></button>',
                nextArrow: '<button type="button" class="slick-next"><i class="icon-chevron-thin-right"></i></button>'
            });
        });

        $('.gallery-popup').foundation('open');
    });

    $('[data-image]').on('click', function () {
        $('.gallery-popup__inner')
        .empty()
        .append(
            '<div class="slider slider--arrows" data-slick=\'{ "slidesToShow": 1 }\'>' +
            '<div class="slider__slide"><img src="' + $(this).data('image') + '"></div>' +
            '</div >'
            );

        $('.gallery-popup .slider').slick({
            prevArrow: '<button type="button" class="slick-prev"><i class="icon-chevron-thin-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="icon-chevron-thin-right"></i></button>'
        });

        $('.gallery-popup').foundation('open');
    });

    $('[data-video]').on('click', function () {
        $('.gallery-popup__inner')
        .empty()
        .append(
            '<div class="responsive-embed">' +
            '<iframe width="560" height="315" src="' + $(this).data('video') + '" frameborder="0" allowfullscreen ></iframe>' +
            '</div>'
            );

        $('.gallery-popup').foundation('open');
    });

    $('.gallery-popup').on('closed.zf.reveal', function () {
        // empty the popup to stop any videos playing
        $('.gallery-popup__inner').empty();
    });
}

function initCapacityTables() {

    $('#feet, #mtr').on('click', function () {
        if ($(this).hasClass('active')) {
            return; // halt if this is already selected
        }

        $('#feet, #mtr').toggleClass('active');

        if ($(this).attr('id') === 'feet') { // convert meters to feet
            $('#areaUnits').text('Ft');
            $('.dimension').each(function () {
                $(this).text(parseFloat(parseFloat($(this).text()) * 3.2808).toFixed(2));
            });
            $('.areadimension').each(function () {
                $(this).text(parseFloat(parseFloat($(this).text()) / 0.09290304).toFixed(2));
            });
        } else if ($(this).attr('id') === 'mtr') { // convert feet to meters
            $('#areaUnits').text('M');
            $('.dimension').each(function () {
                $(this).text(parseFloat(parseFloat($(this).text()) / 3.2808).toFixed(2));
            });
            $('.areadimension').each(function () {
                $(this).text(parseFloat(parseFloat($(this).text()) * 0.09290304).toFixed(2));
            });
        }
    });

}

function initBookingWidget() {

    if ($('.booking-widget__picker').length === 0) {
        return;
    }

    // date picker
    var today = new Date();

    $('.booking-widget__picker input[name="dateIn"]').each(function (i, el) {
        $(el).fdatepicker({
            initialDate: today,
            startDate: today,
            weekStart: 1,
            format: 'mm/dd/yyyy',
            leftArrow: '<i class="icon-chevron-thin-left"></i>',
            rightArrow: '<i class="icon-chevron-thin-right"></i>',
            appendTo: $(el).closest('form').find('.booking-widget__datepicker')
        }).on('show', function () {
                // disable open triggers
                $('.booking-widget__arrival-day, .booking-widget__arrival-month-year').addClass('disabled');
                // hide some fields on smaller screens
                $('.booking-widget input[name="discount"], .booking-widget__nights, .booking-widget__guests, .booking-widget__rooms, .booking-widget__children').addClass('faded');
            })
        .on('hide', function () {
                // enable open triggers
                setTimeout(function () {
                    $('.booking-widget__arrival-day, .booking-widget__arrival-month-year').removeClass('disabled');
                }, 200);
                // show fields again
                $('.booking-widget input[name="discount"], .booking-widget__nights, .booking-widget__guests, .booking-widget__rooms, .booking-widget__children').removeClass('faded');
            })
        .on('changeDate', function (e) {
                // update display date
                var day = e.date.getDate();
                var month = $.fn.fdatepicker.dates['en'].months[e.date.getMonth()];
                var year = e.date.getFullYear();

                var input = $(e.target);

                input.siblings('.booking-widget__arrival-day').text(day < 10 ? '0' + day : day);
                input.siblings('.booking-widget__arrival-month-year').text(month + ' ' + year);
            }).trigger({
                type: 'changeDate',
                date: today
            });
        });

    // open date picker when clicking date display fields
    $('.booking-widget__arrival-day, .booking-widget__arrival-month-year').on('click', function () {
        if (!$(this).hasClass('disabled')) {
            $(this).siblings('input[name="dateIn"]').fdatepicker('show');
        }
    });

    var maxPeople = parseInt($('#booking-widget-room').data('max-people'))
   
    // picker changers
    $('.booking-widget__picker-up').on('click', function () {
        var input = $(this).next('input');
        var val = parseInt(input.val());

        if (isNaN(val)) {
            return;
        }

        // set limit to maxPeople
        if (input.hasClass('adults-input') || input.hasClass('children-input')) {
            var adults = parseInt($('.adults-input').val())
            var children = parseInt($('.children-input').val())
            var sum = adults + children

            if (sum === maxPeople) return
            val += 1;

            var pattern = input.attr('pattern');
            var regex = new RegExp('^' + pattern + '$');

            if (regex.test(val)) {
                input.val(val);
            }
            return
        }

        val += 1;

        // test new value against field pattern
        var pattern = input.attr('pattern');
        var regex = new RegExp('^' + pattern + '$');

        if (regex.test(val)) {
            // matches pattern, so change it
            input.val(val);
        }
    });

    $('.booking-widget__picker-down').on('click', function () {
        var input = $(this).prev('input');
        var val = parseInt(input.val());

        if (isNaN(val)) {
            return;
        }

        val -= 1;

        // test new value against field pattern
        var pattern = input.attr('pattern');
        var regex = new RegExp('^' + pattern + '$');

        if (regex.test(val)) {
            // matches pattern, so change it
            input.val(val);
        }
    });

    // reset booking widget to default room booking
    $('#offCanvasBooking').on('closed.zf.offcanvas', function (e) {
        if (!$('#booking-widget-room-button').hasClass('hide')) {
            setTimeout(function () {
                $('#booking-widget-room-button').trigger('click');
            }, 200);
        }
    });

    // any link with #book should open the booking widget
    $('a[href="#book"]').on('click', function (e) {
        $('#offCanvasBooking').foundation('open');

        e.preventDefault();
        return false;
    });

    // any link with #book-table should open the table booking widget
    $('a[href="#book-table"]').on('click', function (e) {
        $('#offCanvasBooking').foundation('open');

        if (!$('#booking-widget-table-button').hasClass('hide')) {
            setTimeout(function () {
                $('#booking-widget-table-button button').trigger('click');
            }, 100);
        }

        e.preventDefault();
        return false;
    });

    // booking toggles
    $('#booking-widget-table-button button').on('click', function () {
        $('#booking-widget-room').addClass('hide');
        $('#booking-widget-spa').addClass('hide');
        $('#booking-widget-table').removeClass('hide');

        $('#booking-widget-table-button').addClass('hide');
        $('#booking-widget-room-button').removeClass('hide');
        $('#booking-widget-spa-button').removeClass('hide');
    });

    $('#booking-widget-spa-button button').on('click', function () {
        $('#booking-widget-room').addClass('hide');
        $('#booking-widget-table').addClass('hide');
        $('#booking-widget-spa').removeClass('hide');

        $('#booking-widget-spa-button').addClass('hide');
        $('#booking-widget-room-button').removeClass('hide');
        $('#booking-widget-table-button').removeClass('hide');
    });

    $('#booking-widget-room-button button').on('click', function () {
        $('#booking-widget-table').addClass('hide');
        $('#booking-widget-spa').addClass('hide');
        $('#booking-widget-room').removeClass('hide');

        $('#booking-widget-room-button').addClass('hide');
        $('#booking-widget-table-button').removeClass('hide');
        $('#booking-widget-spa-button').removeClass('hide');
    });
}

function initMobile() {
    if (window.outerWidth >= 800) {
        return;
    }

    var windowHeight = 0;

    window.onresize = function () {
        if (window.innerHeight === windowHeight) {
            return;
        }

        // change bottom booking bar top position based on window height (used for iOS UI bar)
        $('.header__button--book').css('top', window.innerHeight - 72);

        // update variable for next change event
        windowHeight = window.innerHeight;
    }

    // initial call
    window.onresize();
}

function initOfferFilters() {
    $('.offer-filters .button').on('click', function () {
        var btn = $(this);

        // toggle filter buttons
        btn.addClass('active').blur();
        btn.siblings('.button').removeClass('active');

        // filter offers
        var filters = btn.closest('.offer-filters');
        var filterMonths = [];
        filters.find('button.active').filter(function () { filterMonths = filterMonths.concat($(this).data('months')); });
        var offers = filters.siblings('.offer-cta');

        if (filterMonths.length === 0) {
            // show all offers
            offers.show();
        }
        else {
            // only show valid offers
            offers.each(function () {
                var offer = $(this);
                var offerMonths = offer.data('months');

                var isValidOffer = offerMonths.some(function (month) {
                    return (filterMonths && filterMonths.indexOf(month) >= 0);
                });

                offer.toggle(isValidOffer);
            });
        }

        // show message when no offers are found
        var hasNoOffers = !offers.is(':visible');

        filters.find('.offer-filters__no-offers').toggle(hasNoOffers);
    });
}

var currentDeviceSize = '';

function initVideoHeader(){

  $('#home-video-play-img, .iframe-wrap').click(function() {
    console.log('iframe clicked');
    var iframeMobile = $('iframe#video-mobile');
    var playerMobile = new Vimeo.Player(iframeMobile);

    playerMobile.getPaused().then(function(paused) {
        if ( paused == true ) {
            playerMobile.play();
            homeVideoPlay();
        } else {
            playerMobile.pause();
            homeVideoPause();
        }
    });

});


$('#home-video-play').click(function(){
  $('.video-mobile-placeholder').hide();
});

function homeVideoPlay() {
      $('#home-video-play-img').hide();
  }

  function homeVideoPause() {
      $('#home-video-play-img').show();
  }

//home video volume button
////home - video - volume button - desktop

$('.video-bar input[type="checkbox"]').on('change', function () {

    var iframeDesktop = $('iframe#desktop-video');
    var playerDesktop = new Vimeo.Player(iframeDesktop);

    if ($(".video-desktop .video-bar").hasClass("off")) {
  playerDesktop.setVolume(0.66);
        //alert('turn on');
        $('.video-desktop .video-bar').addClass('on');
        $('.video-desktop .video-bar').removeClass('off');
        $('.video-bar label').addClass('on');
        $('.video-bar label').removeClass('off');

        $(".video-desktop #volumeSwitch").checked = true;



} else if ($(".video-desktop .video-bar").hasClass("on")) {
  playerDesktop.setVolume(0);
        //alert('turn off');
        $('.video-desktop .video-bar').addClass('off');
        $('.video-desktop .video-bar').removeClass('on');
        $('.video-bar label').addClass('off');
        $('.video-bar label').removeClass('on');

        $(".video-desktop #volumeSwitch").checked = false;
}



    var iframeMobile = $('iframe#video-mobile');
    var playerMobile = new Vimeo.Player(iframeMobile);

  //alert('turn on');
  if ($(".video-mobile .video-bar").hasClass("off")) {
playerDesktop.setVolume(0.66);
      //alert('turn on');
      $('.video-mobile .video-bar').addClass('on');
      $('.video-mobile .video-bar').removeClass('off');
      $('.video-bar label').addClass('on');
      $('.video-bar label').removeClass('off');

      $(".video-mobile #volumeSwitch").checked = true;

} else if ($(".video-mobile .video-bar").hasClass("on")) {
  playerMobile.setVolume(0);
  //alert('turn off');
  $('.video-mobile .video-bar').addClass('off');
  $('.video-mobile .video-bar').removeClass('on');
  $('.video-bar label').addClass('off');
  $('.video-bar label').removeClass('on');

  $(".video-mobile #volumeSwitch").checked = false;
}
});





// function initVideo() {

if ($('.videoWrapper').length > 0) {
  if ( $(window).outerWidth() <= 800 ) {
    currentDeviceSize = 'small';
  } else {
    currentDeviceSize = 'medium';
  }

  $(window).on('resize', function () {
    if ( $(window).outerWidth() <= 800 ) {
      var newDeviceSize = 'small';
    } else {
      var newDeviceSize = 'medium';
    }
    if (currentDeviceSize !== newDeviceSize) {
      window.location.href = window.location.href;
      // alert ('hi')
      return false;
    } else {
      currentDeviceSize = newDeviceSize;
      // alert('bye')
    }
  });
}

if ( $('.video-desktop .videoWrapper iframe').length > 0 ) {
        coverViewport('.video-desktop .videoWrapper iframe', true);
        $(window).on('resize', function () {
            coverViewport('.video-desktop .videoWrapper iframe', true);
        });
    }
// }


if ($('.video-desktop').length > 0) {
  $('header').removeClass('header--no-slider header--no-logo-top');
}
}

function isElementInViewport (el) {
    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    var t = rect.y;
    var b = window.innerHeight - rect.y - rect.height;
    var h = window.innerHeight;

    if (
	    ( (t >= 0) && (t <= h) ) ||
	    ( (b >= 0) && (b <= h) )
    ) {
	    return true;
    } else {
	    return false;
    }
}

/**
 * Scales any element to simulate the CSS background-size: cover property.
 * @param {DOMelement} element The element to scale
 * @param {bool} [scaleToParent] Scale the element to fill the parent instead
 *                               of the whole browser window.
 */
 function coverViewport(element, scaleToParent) {
    if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

    var viewportWidth, viewportHeight;
    if (scaleToParent) {
        viewportWidth = $(element).parent().innerWidth();
        viewportHeight = $(element).parent().innerHeight();
    } else {
        viewportWidth = $(window).innerWidth();
        viewportHeight = $(window).innerHeight();
    }

    var $element = $(element);

    var elementWidth = $element.attr('width');
    var elementHeight = $element.attr('height');

    // Aspect ratio = height / width
    var viewportRatio = viewportHeight / viewportWidth;
    var videoRatio = elementHeight / elementWidth;

    //console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);

    var newWidth = (elementWidth / elementHeight) * viewportHeight
    var newHeight = (elementHeight / elementWidth) * viewportWidth;
    var newTop = 0;

    if (viewportRatio > videoRatio) {
        // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
        //console.log('crop left and right');
        $element.css('height', viewportHeight);
        $element.css('width', newWidth);
        $element.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
        $element.css('top', '0');
    } else if (viewportRatio < videoRatio) {
        // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
        //console.log('crop top and bottom');
        $element.css('width', viewportWidth);
        $element.css('height', newHeight);
        $element.css('left', '0');
        $element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
    } else {
        // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
        //console.log('no crop');
        $element.css('height', viewportHeight);
        $element.css('width', viewportWidth);
        $element.css('left', '0');
        $element.css('top', '0');
    }
}

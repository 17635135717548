$(document).ready(function () {
	initOffCanvasSubNav();
	intSpaTreatments();
});


function initOffCanvasSubNav() {
	$('.off-canvas__links > li').each(function () {
		// Automatically add the "Overview" links to the landing page
		if ( $(this).find('ul').length > 0 ) {
			var overviewLink = $(this).find('a').first().attr('href');
			$(this).find('ul').prepend('<li class="offcanvas__overview-link"><a href="' + overviewLink + '">Overview</a></li>');
		}
	});
	
	
	$('.off-canvas__links > li > a').on('click', function () {
		if ( $(this).next('.off-canvas__links-sub').length > 0 ) { // only trigger if there are sub pages to show
			if ( $(this).parent().hasClass('open') ) {
				// second click of main item allows clicking directly through
				return true;
			} else {
				// close any other open items
				$('.off-canvas__links > li.open').removeClass('open').find('.off-canvas__links-sub').slideUp();
				
				// and open the new one
				$(this).parent().addClass('open');
				$(this).next().slideDown();
			}
			return false; // prevent navigating
		}
	});
}


function intSpaTreatments() {
	$('.treatments__read-more button').on('click', function () {
		var $item = $(this).parents('.treatments__item');
		$(this).parent().hide();
		$item.find('.treatments__description-more').slideDown();
		$item.find('.treatments__read-less').show();
	});
	
	$('.treatments__read-less button').on('click', function () {
		var $item = $(this).parents('.treatments__item');
		$(this).parent().hide();
		$item.find('.treatments__description-more').slideUp();
		$item.find('.treatments__read-more').show();
	});
	
	$('.contact-opening__times').accordiom({
		showFirstItem: true
	});
}
